<template>
<div class="subject-box">
    <div class="row comparable-row">
            <div class="comparable-left">
                <p class="title">Subject Detail</p>
                <div>
                    <slot name="title"></slot>
                    <div class="row item-common  item-row">
                        <span class="item-title">Property Type</span>
                        <span class="item-contact" v-if="data.PropertyType==='Commercial'">Commercial</span>
                        <span class="item-contact" v-else-if="data.PropertyType==='1 Family'||data.PropertyType==='Single Family'">Single Family</span>
                        <span class="item-contact" v-else-if="data.PropertyType==='Land'">Land</span>
                        <span class="item-contact" v-else>Condo/Multi Family</span>
                    </div>
                    <slot name="price-title"></slot>
                    <div class="row item-common item-row">
                        <span class="item-title">Area</span>
                        <span class="item-contact">{{data.MLSAreaMajor}}</span>
                    </div>
                    <div class="row item-common item-row">
                        <span class="item-title">Zoning</span>
                        <span class="item-contact">{{data.Zoning}}</span>
                    </div>
                    <div class="row item-common item-row">
                        <span class="item-title">Second Dwelling</span>
                        <span class="item-contact">{{data.LINK_listing_dwell2}}</span>
                    </div>
                    <div class="row item-common item-row">
                        <span class="item-title">Sewer</span>
                        <p class="item-contact"><span v-for="(item,index) in data.Sewer" :key="index">{{item==='Twn'?'Town':item}}</span></p>
                    </div>
                    <div class="row item-common item-row">
                        <span class="item-title">Lot(SF)</span>
                        <span class="item-contact">{{data.LotSizeSquareFeet | currencyPrice("",0)}}</span>
                    </div>
                    <div class="row item-common item-row">
                        <span class="item-title">Living Area (SF)</span>
                        <span class="item-contact">{{data.BuildingAreaTotal}}</span>
                    </div>
                    <div class="row item-common item-row">
                        <span class="item-title">Bedrooms</span>
                        <span class="item-contact">{{data.BedroomsTotal}}</span>
                    </div>
                     <div class="row item-common item-row">
                        <span class="item-title">Year Built</span>
                        <span class="item-contact">{{data.YearBuilt}}</span>
                    </div>
                    <div class="row item-common item-row">
                        <span class="item-title">Views</span>
                        <p class="item-contact"><span v-for="(item,index) in data.View" :key="index">{{item==='Twn'?'Town':item}} </span></p>
                    </div>
                    <div class="row item-common item-row">
                        <span class="item-title">Water Frontage</span>
                        <p class="item-contact"><span v-for="(item,index) in data.FrontageType" :key="index">{{item}}</span></p>
                    </div>
                </div>
            </div>
            <div class="comparable-right">
                <p class="title"><slot name="name"></slot></p>
                <div>
                    <slot name="info"></slot>
                     <div class="item-common">
                         <el-switch v-model="property_type2Show" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                         <el-select  v-show="property_type2Show"  v-model="form.property_type2" multiple   placeholder>
                            <el-option v-for="(item,index) in property_types" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <slot name="price"></slot>
                    <div class="item-common">
                        <el-switch v-model="areaShow" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                         <el-select v-show="areaShow" v-model="form.area" multiple  collapse-tags placeholder>
                            <el-option v-for="(item,index) in areas" :key="index" :label="item.name" :value="item.name">
                            </el-option>
                        </el-select>
                    </div>
                     <div class="item-common">
                         <el-switch v-model="zoningShow" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                        <el-select v-show="zoningShow" v-model="form.zoning" multiple  collapse-tags  placeholder>
                            <el-option v-for="(item,index) in zonings" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="item-common">
                        <el-switch v-model="dwell2Show" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                        <el-select v-show="dwell2Show" v-model="form.dwell2" multiple  collapse-tags placeholder>
                            <el-option v-for="(item,index) in dwel2" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                     <div class="item-common">
                         <el-switch v-model="sewerShow" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                         <el-select v-show="sewerShow" v-model="form.sewer" multiple  collapse-tags placeholder>
                            <el-option v-for="(item,index) in sewers" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="item-row  row item-common">
                        <el-switch v-model="lotShow" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                        <div v-show="lotShow" class="price-row row">
                            <el-input v-model="form.lot_size_square_feet_gte " type="number" clearable placeholder="Min"/>
                            <el-input v-model="form.lot_size_square_feet_lte " type="number" clearable placeholder="Max"/>
                        </div>
                    </div>
                     <div class="item-row   row item-common">
                         <el-switch v-model="livingAreaShow" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                         <div v-show="livingAreaShow" class="price-row row">
                            <el-input v-model="form.building_area_total_gte" type="number" clearable placeholder="Min "/>
                            <el-input v-model="form.building_area_total_lte" type="number" clearable placeholder="Max "/>
                         </div>
                    </div>
                    <div class="row item-row item-common">
                        <el-switch v-model="bedroomShow" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                        <div v-show="bedroomShow" class="price-row row">
                            <el-input v-model="form.bedroom_num_gte" type="number" clearable placeholder="Min"/>
                            <el-input v-model="form.bedroom_num_lte" type="number" clearable placeholder="Max "/>
                        </div>
                    </div>
                    <div class="row item-row   item-common">
                        <el-switch v-model="yearBuiltShow" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                        <div v-show="yearBuiltShow" class="price-row row">
                           <el-date-picker v-model="form.year_built_gte" type="year"  format="yyyy" value-format="yyyy" placeholder="Min"> </el-date-picker>
                           <el-date-picker v-model="form.year_built_lte" type="year"  format="yyyy" value-format="yyyy" placeholder="Min"> </el-date-picker>
                        </div>
                    </div>
                    <div class="item-common">
                        <el-switch v-model="viewShow" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                        <el-select  v-show="viewShow" v-model="form.view" multiple placeholder>
                            <el-option v-for="(item,index) in viewList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="item-common">
                        <el-switch v-model="frontageTypeShow" active-color="#8BABB6" inactive-color="#dcdfe6"></el-switch>
                        <el-select v-show="frontageTypeShow" v-model="form.FrontageType" multiple placeholder>
                            <el-option v-for="(item,index) in FrontageTypeList" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
        <p ><el-button @click="viewComparable" :loading="loading"><slot name="btn-name"></slot></el-button></p>
        <slot name="number"></slot>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import dayjs from 'dayjs'
export default {
    props:[ "data", "info","flag" ],
    data(){
        return {
            property_type2Show:true,
            areaShow: false,
            zoningShow: false,
            dwell2Show: false,
            sewerShow: false,
            lotShow: false,
            livingAreaShow: false,
            bedroomShow: false,
            yearBuiltShow: false,
            viewShow: false,
            frontageTypeShow: false,
            form:{
               sewer:[], property_type2:[], dwell2:[], zoning:[],view:[],FrontageType:[],
               area:[],lot_size_square_feet_gte:null,lot_size_square_feet_lte:null,building_area_total_gte:null,building_area_total_lte:null,
               bedroom_num_gte:null,bedroom_num_lte:null,year_built_gte:null,year_built_lte:null,
            },
            property_types:['Commercial','Land','Single Family','Condo/Multi Family'],
            FrontageTypeList:["Bay/Harbor","None","Oceanfront","Pnd","Snd"],
            viewList:[
                {id:"D/Hrb",name:"D/Hrb"},
                {id:"D/Oc",name:"D/Oc"},
                {id:"D/Pnd",name:"D/Pnd"},
                {id:"D/Snd",name:"D/Snd"},
                {id:"Hrb",name:"Hrb"},
                {id:"None",name:"None"},
                {id:"Pasture",name:"Pasture"},
                {id:"Pond",name:"Pond"},
                {id:"Res",name:"Res"},
                {id:"Snd",name:"Snd"},
                {id:"Trees/Woods",name:"Trees/Woods"},
                {id:"Twn",name:"Town"},
                ],
            sewers:[{id:"Septic Tank",name:"Septic"},{id:"Twn",name:"Town"},{id:"Unknown",name:"None"}],
            zonings:["C1","CDT","CMI","CN","CTEC","MMD","LUG1","LUG2","LUG3","None","R1","R2","R5","R10","R40","RC","RC2","RC2","RCTD","ROH","SR2","VR",],
            dwel2:[{id:"P/Sd",name:"Potential"},{id:"E/Sd",name:"Existing"},{id:"None",name:"None"}],
            formNew:{},
            loading: false,
        }
    },
    computed: mapState({
    areas: (state) => state.areas,
    propertyTypeList:(state)=>state.enum.propertyTypeList,
    query(){
        let obj = this.formNew;
        if(this.frontageTypeShow){if(!obj.FrontageType||obj.FrontageType.length===0 || obj.FrontageType === null) delete obj.FrontageType;}else{ delete obj.FrontageType; }
        if(this.dwell2Show){if( !obj.dwell2||obj.dwell2.length===0||obj.dwell2 === null ) delete obj.dwell2;}else { delete obj.dwell2; }
        if(this.sewerShow){if( !obj.sewer||obj.sewer.length===0||obj.sewer === null ) delete obj.sewer;}else {  delete obj.sewer; }
        if(this.viewShow){if(!obj.view||obj.view.length===0) delete obj.view;}else { delete obj.view; }
        if(this.zoningShow){if(!obj.zoning||obj.zoning.length===0) delete obj.zoning;}else{ delete obj.zoning; }
        if(this.areaShow){if(!obj.area||obj.area.length===0 || obj.area===null) delete obj.area;}else { delete obj.area; }
        if(this.property_type2Show){if(!obj.property_type2||obj.property_type2.length===0) delete obj.property_type2;}else { delete obj.property_type2; }
        if(this.lotShow){
            if(!obj.lot_size_square_feet_lte ) delete obj.lot_size_square_feet_lte;
            if(!obj.lot_size_square_feet_gte) delete obj.lot_size_square_feet_gte;
        }else{ delete obj.lot_size_square_feet_gte; delete obj.lot_size_square_feet_lte;}
        if(this.livingAreaShow){
            if(!obj.building_area_total_lte) delete obj.building_area_total_lte;
            if(!obj.building_area_total_gte) delete obj.building_area_total_gte;
        }else { delete obj.building_area_total_gte; delete obj.building_area_total_lte; }
        if(this.bedroomShow){
            if(!obj.bedroom_num_gte) delete obj.bedroom_num_gte; 
            if(!obj.bedroom_num_lte) delete obj.bedroom_num_lte;
        }else { delete obj.bedroom_num_gte; delete obj.bedroom_num_lte;  }
        if(this.yearBuiltShow){
            if(!obj.year_built_gte) delete obj.year_built_gte;
            if(!obj.year_built_lte) delete obj.year_built_lte ;
        }else { delete obj.year_built_gte; delete obj.year_built_lte; }
         return obj;
    }
    }),
    watch:{
        data(newV){
            
            if( this.info.property_type2&& this.info.property_type2.length!=0){
                this.form.property_type2 = this.info.property_type2;
            }else { this.property_type2Show = false; }
            if(this.info.area && this.info.area.length!=0){
              this.form.area = this.info.area; if(this.flag) this.areaShow = true;
            }else { this.areaShow = false; }
            if(this.info.zoning && this.info.zoning.length!=0){
              this.form.zoning = this.info.zoning; if(this.flag) this.zoningShow = true;
            }else { this.zoningShow = false; }
            if(this.info.dwell2 && this.info.dwell2.length!=0){
              this.form.dwell2 = this.info.dwell2; if(this.flag) this.dwell2Show = true;
            }else { this.dwell2Show = false; }
            if(this.info.sewer && this.info.sewer.length!=0){
              this.form.sewer = this.info.sewer; if(this.flag) this.sewerShow = true;
            }else { this.sewerShow = false; }
            if(this.info.view && this.info.view.length!=0){
              this.form.view = this.info.view; if(this.flag) this.viewShow = true;
            }else { this.viewShow = false; }

            if(this.info.FrontageType && this.info.FrontageType.length!=0){
              this.form.FrontageType = this.info.FrontageType; if(this.flag) this.frontageTypeShow = true;
            }else { this.frontageTypeShow = false; }

            if(!this.info.lot_size_square_feet_gte && !this.info.lot_size_square_feet_lte ){
                this.lotShow = false;
            }else{
                if(this.flag) this.lotShow = true;
               this.form.lot_size_square_feet_gte = this.info.lot_size_square_feet_gte ;
               this.form.lot_size_square_feet_lte = this.info.lot_size_square_feet_lte ;
            }
            if(!this.info.bedroom_num_gte&& !this.info.bedroom_num_gte){
               this.bedroomShow = false;
            }else{
                if(this.flag) this.bedroomShow = true;
                this.form.bedroom_num_gte = this.info.bedroom_num_gte;
                this.form.bedroom_num_lte = this.info.bedroom_num_lte;
            }
            if(!this.info.building_area_total_gte&& !this.info.building_area_total_lte){
               this.livingAreaShow = false;
            }else{
                if(this.flag) this.livingAreaShow = true;
                this.form.building_area_total_gte = this.info.building_area_total_gte;
                this.form.building_area_total_lte = this.info.building_area_total_lte;
            }
            if(!this.info.year_built_gte&& !this.info.year_built_lte){
               this.yearBuiltShow = false;
            }else{
                if(this.flag) this.yearBuiltShow = true;
                 this.form.year_built_gte = this.info.year_built_gte;
                 this.form.year_built_lte = this.info.year_built_lte;
            }
        }
    },
    methods:{
         ...mapActions(["getAreas","getPropertyTypeList"]),
        viewComparable(){
            this.formNew = Object.assign({},this.form);
            if(this.formNew.year_built_gte){this.formNew.year_built_gte = dayjs(this.formNew.year_built_gte).format("YYYY")};
            if(this.formNew.year_built_lte){this.formNew.year_built_lte = dayjs(this.formNew.year_built_lte).format("YYYY")}
            this.loading = true;
            this.$emit("viewComparable",this.query);
        }
    },
    created(){
    if (this.areas.length === 0) {
      this.getAreas();
    }
    if (this.propertyTypeList.length === 0) {
      this.getPropertyTypeList();
    }
    }
}
</script>
<style  lang="scss" scoped>
.subject-box{
    position: relative;
    padding-bottom: 20px;
    /deep/ input::-webkit-outer-spin-button, /deep/input::-webkit-inner-spin-button { -webkit-appearance: none; }
    /deep/input[type="number"]{ -moz-appearance: textfield; }
    .comparable-row{
        padding: 20px;align-items: flex-start;
        .comparable-left{width: 40%;}
        .comparable-right{width: 60%;}
        .title{font-family: 'Roboto-Bold';font-size: 18px;text-align: left;}
        .item-common{height: 48px; line-height: 48px;text-align: left;
        /deep/.el-select,/deep/.el-input{width: 100%;max-width: 300px;}
        /deep/ .el-switch{margin-right: 20px;}
        }
        .item-common.item-price{ 
            /deep/ .el-date-editor.el-range-editor{width: 300px;}
            /deep/.el-select.select-date ,/deep/.el-input {width: 361px;max-width: 361px;} 
             /deep/.el-input.el-date-editor{width: 145px;max-width: 145px;
             &:first-of-type{margin-right: 10px;}} 
            }
        .item-row{
            /deep/ .el-input{width: 105px;margin: 0 10px;}
            .item-title{font-family: 'Roboto-Medium';font-size: 14px;display: inline-block;width: 180px;text-align: left;}
            .item-contact{font-family: 'Roboto-Regular';font-size: 14px;}
        }
        
        .price-row {justify-content: space-between;  max-width: 300px;
            /deep/ .el-input{width:calc(calc(100% - 10px) / 2);margin: 0;}
        }
    }
    .el-button{color: #fff; background-color: #678993;}
    &::after{
        content: ""; position: absolute;bottom: 0;left:0;width: 100%;border-bottom:1px solid #E1E9EF;
    }
    .show-number{
    text-align: left;
    font-size: 18px;
    margin: 0 0 0px 20px;
  }
}
</style>