<template>
  <div class="add-comment">
    <el-table :data="datas" type="index">
      <el-table-column label="DATE" width="150px">
        <template slot-scope="scope">
          <p>{{ scope.row.date | dateFormat }}</p>
        </template>
      </el-table-column>
      <el-table-column width="200px" label="POSTED BY">
        <template slot-scope="scope">
          <p>{{ scope.row.posted_by }}</p>
        </template>
      </el-table-column>
      <el-table-column label="NOTE" min-width="300">
        <template slot-scope="scope">
          <div class="note-text" v-if="scope.row.content.length <= 400">
            {{ scope.row.content }}
          </div>
          <div v-else-if="scope.row.content.length > 400">
            <div v-show="!scope.row.show">
              <p class="note-text">{{ scope.row.content | intercept }}...</p>
              <p class="fr" @click="openShow(scope.$index)">READ MORE</p>
            </div>
            <div v-show="scope.row.show">
              <p class="note-text">{{ scope.row.content | character }}</p>
              <p class="fr" @click="closeShow(scope.$index)">PACK UP</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="50">
        <template slot-scope="scope">
          <i
            style="color: #f56c6c"
            class="del-btn el-icon-delete"
            @click="openDelNoteDialog(scope.row.comment_uuid)"
          ></i>
        </template>
      </el-table-column>
    </el-table>
    <Page :total="total" :pageSize="5" @number="pagesChanged"></Page>
    <Del-Note @del="delNote" :title="title" ref="delInfor"></Del-Note>
    <p class="title">Add Comment</p>
    <el-input
      resize="none"
      type="textarea"
      :autosize="{ minRows: 10 }"
      placeholder
      v-model="comment"
    >
    </el-input>
    <p class="btn">
      <el-button @click="save">Save Comments</el-button>
    </p>
  </div>
</template>
<script>
import {
  apiGetLinkListingComments,
  apiCreatedLinkListingComment,
  apiDelLinkListingComments,
} from "../../../API/api";
import Page from "../../common/Paging";
import DelNote from "../../common/DelDialog";
export default {
  components: { DelNote, Page },
  data() {
    return {
      comment: "",
      data: [],
      id: "",
      title: "Are you sure to delete this note?", // 删除note
      total: 1,
      currentpage: 1,
    };
  },
  computed: {
    datas() {
      var arr = [];
      if (this.data.length != 0) {
        this.data.forEach((item) => {
          let obj = { show: false };
          item = Object.assign(obj, item);
          arr.push(item);
        });
        return arr;
      }
    },
    offset() {
      return (this.currentpage - 1) * 5;
    },
    query() {
      return {
        offset: this.offset,
        limit: 5,
      };
    },
  },
  filters: {
    intercept(val) {
      let arr = "";
      arr = val.slice(0, 400);
      return arr;
    },
  },
  methods: {
    openShow(index) {
      let a = this.datas[index];
      a.show = true;
      this.$set(this.datas, index, a);
    },
    // note
    openDelNoteDialog(id) {
      // 删除评论
      this.id = id;
      this.$refs.delInfor.show = true;
    },
    delNote() {
      this.$refs.delInfor.loading = true;
      apiDelLinkListingComments(this.$route.params.id, this.id)
        .then((res) => {
          this.data.forEach((item, index) => {
            if (this.id === item.comment_uuid) {
              this.noteData.splice(index, 1);
              this.$refs.delInfor.show = false;
            }
          });
        })
        .finally(() => {
          this.$refs.delInfor.loaidng = false;
        });
    },
    closeShow(index) {
      let a = this.datas[index];
      a.show = false;
      this.$set(this.datas, index, a);
    },
    save() {
      apiCreatedLinkListingComment(this.$route.params.id, {
        content: this.comment,
      })
        .then((res) => {
          this.getLinkComments();
          this.comment = "";
        })
        .finally(() => {
          this.$refs.comment.show = false;
        });
    },
    pagesChanged(number) {
      this.currentpage = number;
      this.getLinkComments();
    },
    getLinkComments() {
      apiGetLinkListingComments(this.$route.params.id, this.query).then(
        (res) => {
          this.data = res.results;
          this.total = res.count;
        }
      );
    },
  },
  created() {
    this.getLinkComments();
  },
};
</script>
<style lang="scss" scoped>
.add-comment {
  padding: 20px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #e1e9ef;
  }
  .title {
    text-align: left;
    font-family: "Roboto-Medium";
  }
  /deep/ .el-textarea__inner {
    background-color: #f4f9fc;
  }
  .btn {
    text-align: right;
    .el-button {
      color: #678993;
      border-color: #678993;
    }
  }
}
</style>