<template>
    <div class="listing-box">
        <div v-for="(item,index) in data" :key="index">
            <div class="contact-row row">
              <img v-lazy="item.images.length!=0?item.images[0].small_url:''" alt="">
              <div class="row info-row">
                <div class="info-left">
                  <div class="item-row row">
                    <span class="item-title">{{item.MLSAreaMajor}}</span><span class="item-basic">{{item.StreetNumber}} {{item.StreetName}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">List Price</span><span class="item-basic">{{item.ListPrice==null ? null : item.ListPrice | currencyPrice}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Sale Price</span><span class="item-basic">{{item.ClosePrice==null ? null : item.ClosePrice | currencyPrice}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Property Type</span>
                        <span class="item-title" v-if="item.PropertyType==='Commercial'">Commercial</span>
                        <span class="item-title" v-else-if="item.PropertyType==='1 Family'||item.PropertyType==='Single Family'">Single Family</span>
                        <span class="item-title" v-else-if="item.PropertyType==='Land'">Land</span>
                        <span class="item-title" v-else>Condo/Multi Family</span>
                    <!-- <span class="item-title">Property Type</span><span class="item-basic">{{item.PropertyType}}</span> -->
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Bedrooms</span><span class="item-basic">{{item.BedroomsTotal}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Lot (SF)</span><span class="item-basic">{{item.LotSizeSquareFeet}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Year Built</span><span class="item-basic">{{item.YearBuilt}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Water Views</span><p style="margin:0" class="item-basic"><span v-for="(n,i) in item.View" :key="i">{{i+1==item.View.length?n:n+", "}}</span></p>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Sewer</span><p style="margin:0" class="item-basic"><span v-for="(n,i) in item.Sewer" :key="i">{{i+1==item.Sewer.length?n:n+", "}}</span></p>
                  </div>
                </div>
                <div class="info-right">
                  <div class="item-row row">
                    <span class="item-title">Sale Date</span><span class="item-basic">{{item.CloseDate?item.CloseDate:null | dateFormat}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">DOM</span><span class="item-basic">{{item.DaysOnMarket? item.DaysOnMarket:null | currencyPrice("",0)}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Zoning</span><span class="item-basic">{{item.Zoning}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Bathrooms</span><span class="item-basic">{{item.BathroomsFull}} full {{item.BathroomsHalf?", "+item.BathroomsHalf:""}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Living Area (SF)</span><span class="item-basic">{{item.BuildingAreaTotal | currencyPrice("",0)}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Year Renovated</span><span class="item-basic">{{item.YearBuiltEffective}}</span>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Water Frotage</span><p style="margin:0" class="item-basic"><span v-for="(n,i) in item.FrontageType" :key="i">{{i+1==item.FrontageType.length?n:n+", "}}</span></p>
                  </div>
                  <div class="item-row row">
                    <span class="item-title">Second Dwelling</span><span class="item-basic">{{item.LINK_listing_dwell2}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="describe">{{item.LINK_descr}}</div>
           <div class="all-btn">
               <el-button v-if="judgeArr(item.ListingId)===-1" class="blue-btn" @click="addReport(item.ListingId)">Add to Report</el-button>
               <el-button class="red-btn" v-else @click="removeReport(item.ListingId)">Remove from Report</el-button>
               <!-- <el-button class="red-btn" @click="removeListData(item.ListingId,index)">Remove from List</el-button> -->
               <!-- <el-button class="green-btn" @click="addComment(item.ListingId)">Add Comment</el-button> -->
           </div>
           <el-divider></el-divider>
        </div>
    </div>
</template>
<script>
export default {
    props:["data"],
    methods:{
      judgeArr(id){
        let arr = []
        arr = this.$store.state.linksAllId;
        if(arr&&arr.length!=0){
          let index = arr.indexOf(id);
          return index
        }else{
          return -1
        }
      },
      removeReport(id){
        let arr = this.$store.state.linksAllId;
        arr.forEach((item,index) => {
           if(id===item){
             arr.splice(index,1);
           }
        });
        this.$store.commit('setlinksAllId', arr);
      },
      addReport(id){
        this.$emit("addReport",id)
      },
      removeListData(id,index){ 
        this.$emit("removeListData",id,index) 
      },
      addComment(id){ this.$emit("addComment",id) }
    }
}
</script>
<style lang="scss" scoped>
  .listing-box{
      padding: 20px;
      position: relative;
      margin-bottom: 20px;
      // &::after{position: absolute;bottom: 0;left:0;width: 100%;border-bottom:1px solid #E1E9EF;content: "";}
    .contact-row{
       justify-content: space-between;
        img{width: 220px;height: 200px;object-fit: cover;}
        .info-row{align-items: flex-end;width: calc(100% - 250px);
        .item-title{height: 26px; line-height: 26px;text-align: left;font-family: 'Roboto-Medium';font-size: 14px;display: inline-block;width: 110px;text-align: left;
        }
        .item-basic{font-family: 'Roboto-Regular';font-size: 14px;}
        .info-left{width: 60%;
        }
        .info-right{width: 40%;.item-title{width: 140px;}}
        }
      }
    .describe{font-size: 14px;font-family: 'Roboto-Regular';text-align: left;line-height: 25px;}
    .all-btn{text-align: left;margin-top:10px;
    .blue-btn{color: #678993;border-color: #678993;}
    .red-btn{color: #DE1B4B;border-color: #DE1B4B;}
    .green-btn{color: #37A000;border-color: #37A000;}
    }
  }
</style>