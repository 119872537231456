<template>
    <div class="comment-box">
        <el-dialog title="Comment" :visible.sync="show">
        <div class="dialog-contect">
            <p class="title">Add Comment</p>
            <el-input resize="none"
            type="textarea"
            :autosize="{ minRows: 6}"
            placeholder=""
            v-model="comment">
            </el-input>
            <p><el-button class="btn" @click="save">Save Comment</el-button></p>
        </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data(){
        return {
          comment:"",
          show: false,
        }
    },
    methods: {
        save(){
            this.$emit("save",this.comment)
        }
    },
}
</script>
<style lang="scss" scoped>
@import "../../../css/dialog.scss";
  .comment-box{
      /deep/ .el-dialog{
          width: 540px;
          .title{text-align: left;font-family: 'Roboto-Medium';}
          @media (max-width:992px) {
              width: 90px;
          }
      }
      .btn{color: #678993; border-color: #678993;background-color: #fff;}
  }
</style>